import { graphql, useStaticQuery } from 'gatsby';

function useFurniture() {
  const { nodes } = useStaticQuery(query).allContentfulFurniture;
  return nodes;
}

const query = graphql`
  query useProjectsGallery {
    allContentfulFurniture(sort: { fields: sortBy, order: ASC }) {
      nodes {
        id
        description {
          description
        }
        slug
        title
        image {
          fluid(maxWidth: 520) {
            ...GatsbyContentfulFluid
          }
          title
        }
      }
    }
  }
`;

export default useFurniture;
