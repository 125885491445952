import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import GatsbyImage from 'gatsby-image';
import { Link as GatsbyLink } from 'gatsby';

const StyledProjectItem = styled(GatsbyLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  text-align: center;
  ${({ theme }) => theme.sizes.smallP};
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.playfair};
  margin-top: 0.8rem;
  text-align: center;
  ${({ theme }) => theme.sizes.f3};
`;

const Image = styled(GatsbyImage)`
  height: 350px;
`;

function ProjectItem({
  date,
  description,
  image,
  imageHeight,
  slug,
  title,
  ...rest
}) {
  return (
    <StyledProjectItem to={`/furniture/${slug}/`} {...rest}>
      <Image alt={image.title} fluid={image.fluid} />
      <Title>{title}</Title>
      <Description>{description.description}</Description>
    </StyledProjectItem>
  );
}

ProjectItem.propTypes = {
  date: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  image: PropTypes.shape({
    fluid: PropTypes.any.isRequired,
  }).isRequired,
  title: PropTypes.string,
};

ProjectItem.defaultProps = {};

export default ProjectItem;
