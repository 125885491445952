import React from 'react';
// import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Container from 'components/Container';
import Layout from 'components/Layout';
import Nav from 'components/Nav';
import ProjectList from 'pageComponents/furniture/ProjectList';
import SEO from 'components/SEO';

function Furniture({ data, location }) {
  const { pageTitle, pageDescription, seoImage } = data.contentfulPageSeo;
  const seo = {
    description: pageDescription,
    imageUrl: seoImage.file.url,
    title: pageTitle,
  };
  return (
    <Layout>
      <SEO {...seo} />
      <Nav location={location} navTheme="dark" />
      <Container>
        <ProjectList />
      </Container>
    </Layout>
  );
}

Furniture.propTypes = {};

Furniture.defaultProps = {};

export const query = graphql`
  query FurniturePageQuery {
    contentfulPageSeo(identifier: { eq: "furniture" }) {
      pageTitle
      pageDescription
      seoImage {
        file {
          url
        }
      }
    }
  }
`;

export default Furniture;
