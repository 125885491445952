import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'styles/theme';
import ProjectItem from '../ProjectItem';
import useProjects from './useProjects';

const StyledProjectList = styled.div`
  padding-top: 100px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  ${media.ns} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.lg} {
    grid-gap: 60px;
  }
`;

function ProjectList() {
  const projects = useProjects();
  return (
    <StyledProjectList>
      {projects.map(project => (
        <ProjectItem key={project.id} {...project} />
      ))}
    </StyledProjectList>
  );
}

ProjectList.propTypes = {};

ProjectList.defaultProps = {};

export default ProjectList;
